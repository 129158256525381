export var ui_translations_german = {
    'Member': 'Mitglied',
    'Guest': 'Gast',
    '{count} {noun}': '{count} {noun}',
    '{count} {noun} {isAre} here': '{count} {noun} {isAre} sind hier',
    'Hello {name}': 'Hallo {name}',
    'comment': 'Kommentar',
    'comments': 'Kommentare',
    'person': 'Person',
    'people': 'Leute',
    'is': 'ist',
    'are': 'sind',
    'Like': "Gefällt mir",
    'Unlike': "Gefällt mir nicht",
    'Reply': 'Antworten',
    'Collapse': 'Einklappen',
    'No comments yet. Start the conversation!': 'Noch keine Kommentare. Beginne die Unterhaltung!',
    'Share your thoughts': 'Teilen Sie Ihre Sichtweise',
    'Join the conversation by submitting a comment. Be sure to follow our [community guidelines](https://example.com).': 'Nimm an der Unterhaltung teil, indem du einen Kommentar abgibst. Stelle sicher, dass du unseren [Community-Richtlinien](https://example.com) folgst.',
    'Log in': 'Anmelden',
    'Comments': 'Kommentare',
    'Share your thoughts...': 'Teilen Sie Ihre Sichtweise',
    'Continue with Google': 'Mit Google fortfahren',
    'By continuing, you agree to our [community guidelines](https://example.com) and [Privacy Policy](https://example.com).': 'Durch das Fortfahren stimmst du unseren [Community-Richtlinien](https://example.com) und unserer [Datenschutzrichtlinie](https://example.com) zu.',
    'Log in to comment': 'Melde dich an, um zu kommentieren',
    'Post': 'Posten',
    'Public Comment': 'Öffentlicher Kommentar',
    'Upvote': 'Hochwählen',
    'Edit': 'Bearbeiten',
    'Update': 'Aktualisieren',
    'Cancel': 'Abbrechen',
    '{time}': '{time}',
    '{time} • Edited': '{time} • Bearbeitet', 
    '{minutes} minutes ago': 'Vor {minutes} Minuten',
    '1 minute ago': 'Vor 1 Minute',
    '{hours} hours ago': 'Vor {hours} Stunden',
    '1 hour ago': 'Vor 1 Stunde',
    'Just now': 'Gerade jetzt',
    '1 day ago': 'Vor 1 Tag',
    '{days} days ago': 'Vor {days} Tagen',
    'now': 'jetzt',
    '{minutes}m': '{minutes}m',
    '{hours}h': '{hours}h',
    '{days}d': '{days}t',
    '{years}y': '{years}j',
    'Reply to {authorName}...': 'Antworte {authorName}...',
    'reply': 'antworten',
    'replies': 'antworten',
    'Profile': 'Profil',
    'Log out': 'Abmelden',
    'Read more': 'Mehr lesen',
    'Once you log in, you can use a different display name if you prefer to not reveal your real name': 'Wenn du dich angemeldet hast, kannst du einen anderen Anzeigename verwenden, wenn du deinen echten Namen nicht angezeigt willst',
    'Set your visibility after log in': 'Setze deine Sichtbarkeit nach dem Anmelden',

    // Help
    'Start the conversation': 'Beginne die Unterhaltung',
    'Be the first to share your thoughts': 'Sei der Erste, der seine Gedanken teilt',

    // Feature module names
    'Upvote Comments': 'Kommentare hochwählen',
    'Comment Length Limit': 'Kommentarlängenbegrenzung',
    'Demo Moderation': 'Demo-Moderation',
    'Basic Teaser': 'Grundlegender Teaser',
    'Demo Filter': 'Demo-Filter',

    // Profile
    'Profile name': 'Profilname',
    'Your profile photo': 'Dein Profilfoto',
    'A pseudonym': 'Ein Pseudonym',
    'Enter a pseudonym': 'Gib ein Pseudonym ein',
    'This pseudonym is already in use. Please choose a different one.': 'Dieses Pseudonym wird bereits verwendet. Bitte wähle ein anderes.',
    'Pseudonyms can contain only lower case letters and numbers.': 'Pseudonyme können nur Kleinbuchstaben und Zahlen enthalten.',
    'You can change your pseudonym at most once a week': 'Du kannst dein Pseudonym höchstens einmal pro Woche ändern',
    "Let's get your profile set up": 'Lass uns dein Profil einrichten',
    'How do you want to appear to others in your posts and replies?': 'Wie möchten Sie in Ihren Beiträgen und Antworten auf andere wirken?',
    'Save': 'Speichern',
    'Edit {tLabel}': '{tLabel} bearbeiten',
    'photo and name': 'Foto und Name',
    'Please Wait...': 'Bitte warten...',
    'Continue': 'Fortsetzen',
    'Finish' : 'Fertig', 

}
